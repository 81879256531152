import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import MainContent from '../components/mainContent';
import Jumbo from '../components/jumbo';
import Layout from '../components/Layout';

const infoPage = ({ data }) => (
  <Layout>
    <div>
      <HelmetDatoCms seo={data.datoCmsGuestInfoPage.seoMetaTags} />
      <Jumbo
        image={data.datoCmsGuestInfoPage.headerImage.fluid}
        title={data.datoCmsGuestInfoPage.mainHeading}
        subTitle={data.datoCmsGuestInfoPage.subHeading}
      />

      <MainContent content={data.datoCmsGuestInfoPage.mainText} />
    </div>
  </Layout>
);

export default infoPage;

export const query = graphql`
  query infoPageQuery {
    datoCmsGuestInfoPage {
      mainHeading
      subHeading
      mainText
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      headerImage {
        url
        fluid {
          srcSet
        }
      }
    }
  }
`;
